// export { Pager as default };
/**
 * Generates a pager for ul listing
 * 
 * @param {HTMLElement} collection 
 * @param {Number} perPage 
 */
export default function Pager(collection, perPage) {
	this.collection = collection;
	this.list = Array.prototype.slice.call(this.collection.children);	
	this.perPage = perPage;
	this.currentPage = 1;
	this.len = this.list.length;
	this.numPages = Math.ceil(this.len / this.perPage);
	this.menu = undefined;
	this.nav = undefined;
	this.maxPageOptions = 7;

    this.loadList = this.loadList.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
	this.firstPage = this.firstPage.bind(this);
	this.lastPage = this.lastPage.bind(this);
	this.check = this.check.bind(this);

	this.init();
};
Pager.prototype.debug = function() {
	console.log('Current Page: ' + this.currentPage);
	console.log('Number of Pages: ' + this.numPages);
}
Pager.prototype.init = function () {
	this.drawMenu();
	this.loadList();
};
Pager.prototype.lookup = function (number) {
	return this.menu[number + 1];
};
Pager.prototype.drawMenu = function() {
	this.nav = document.createElement('nav');
	this.nav.id = "pager";
	var pages = "";

	

	for (let x = 0; x < this.numPages; x++) {
		let string = `<li class="num-page" role="presentation"><button class="page-link" aria-label="Go to page ${x + 1}">`;
		string += x + 1;
		string += '</button></li>';
		pages += string;
	}
	
	this.nav.innerHTML =`
	<ul class='pagination' aria-label="Pagination Navigation for Career Data">
    <li role="presentation">
      <button class="page-link" id="first" aria-label='First Page'>
        <span aria-hidden='true'>&laquo;</span>
      </button>
    </li>
	<li role="presentation">
	<button class="page-link" id="previous" aria-label='Previous Page'>
	  <span aria-hidden='true'>Previous</span>
	</button>
  </li>
    ${pages}
    <li role="presentation">
      <button class="page-link" id="next" aria-label="Next Page">
        <span aria-hidden="true">Next</span>
      </button>
    </li>
	<li role="presentation">
	<button class="page-link" id="last" aria-label="Last Page">
	  <span aria-hidden="true">&raquo;</span>
	</button>
  </li>
  </ul>`;

    try {
        document.querySelector('#pager-container').appendChild(this.nav); 
    } catch (e) {
        var pagerEl = document.createElement("div");
        this.collection.after(this.nav)
    }
  
  this.menu = document.querySelector('.pagination');

  this.menu = Array.prototype.slice.call(this.menu.children);
  var _this = this;
  this.menu.forEach(function (e) {
  if (e.firstElementChild.id == "previous") {
  	e.firstElementChild.addEventListener('click', _this.prevPage, false);
  }	else if (e.firstElementChild.id == "next") {
  		e.firstElementChild.addEventListener('click', _this.nextPage, false);
	} else if (e.firstElementChild.id == "first") {
		e.firstElementChild.addEventListener('click', _this.firstPage, false);
	} else if (e.firstElementChild.id == "last") {
		e.firstElementChild.addEventListener('click', _this.lastPage, false);
	} else { e.firstElementChild.addEventListener('click', function(event) {
			_this.toPage(event, e.firstElementChild.innerText);
		}, false);
  }
  }); 
};
Pager.prototype.nextPage = function (event) {
	event.preventDefault();
	//console.log('in Next Page: Current page is ' + this.currentPage);
		 if (this.currentPage == this.numPages) {
    return	
    } else {
    this.currentPage += 1;
    //console.log('in Next Page: Current page is NOW ' + this.currentPage);
    this.loadList();
  	}
};
Pager.prototype.prevPage = function (event) {
	event.preventDefault();
    if (this.currentPage == 1) {
    return	
    } else {
    this.currentPage -= 1;
    this.loadList();
  	}
};
Pager.prototype.firstPage = function (event) {
	event.preventDefault();
	this.currentPage = 1;
    this.loadList();
    
 
};
Pager.prototype.lastPage = function (event) {
	event.preventDefault();
	this.currentPage = this.numPages;
    this.loadList();
    
};
Pager.prototype.toPage = function (event, page) {
	event.preventDefault();
	this.currentPage = parseInt(page);
    this.loadList();
    
  
};

Pager.prototype.loadList = function () {
	//take care of processing menu here
	if (this.numPages > this.maxPageOptions) {
		//this.menu contains li children - hide
		let toHide = []
		let toShow = []

		// assume max 20
		// if current: 1 start: 1 end: 7 
		// if current: 2, start: 1 end 7
		// if current: 3, start 1 end 7
		// if current: 4, start 1, end 7
		// if current: 5, start 2 end 8
		let count = 1
		let newCount = 1
		let menuStart = this.currentPage
		let menuEnd = this.currentPage

		while(count < this.maxPageOptions) {
			console.log("Within the while loop")

			if (menuEnd + 1 <= this.numPages) {
				console.log("Incrementing End and NewCount")
				menuEnd++;
				newCount++;
				console.log("end", menuEnd)
			}
			if(menuStart - 1 > 0) {
				console.log("Decrementing Start and increasing newCount")
				menuStart--
				newCount++
				console.log("start", menuStart)
			}
			if (newCount == count) {
				break;
			} else {
				count = newCount
			}
		}

		console.log({
			menuStart: menuStart,
			menuEnd: menuEnd
		})

		for (let i = 0; i < this.menu.length; i++) {
			if (i >= menuStart && i <= menuEnd || i <= 1 || i > this.menu.length - 3) {
				toShow.push(this.menu[i])
			} else {
				toHide.push(this.menu[i])
			}
		}

		console.log(toShow);
		console.log(toHide);

			//draw menu
		for (let i = 0; i < toHide.length; i++) {
			toHide[i].style.display = "none";
		}
		for (let i = 0; i < toShow.length; i++) {
			toShow[i].style.display = "inline";
		}
	}
	//end menu processing

	var begin = ((this.currentPage - 1) * this.perPage);
	var end = begin + this.perPage;

	var pageList = this.list.slice(begin, end);

	this.drawList(pageList);


	this.check();
	//this.debug();
	//console.log(this.menu)
	//console.log(this)
	if (this.numPages <= 1 ) {this.nav.style.display = "none"}

};

Pager.prototype.drawList = function (pageList) {
	this.collection.innerHTML = "";

	for (let r = 0; r < pageList.length; r++) {
		this.collection.appendChild(pageList[r]);
	};

};
Pager.prototype.check = function () {
		this.menu.forEach(function(e) {
    	e.classList.remove('active');
		e.querySelector('button').removeAttribute("aria-current")
		// need to add aria current to child a element
    	// e.removeAttribute("aria-current");
    });
    this.lookup(this.currentPage).classList.add('active');
    this.lookup(this.currentPage).querySelector('button').setAttribute("aria-current", "page");

	document.getElementById("next").disabled = this.currentPage == this.numPages ? true : false;
	this.currentPage == this.numPages ? document.getElementById("next")
	.parentNode.classList.add('disabled') : document.getElementById("next")
	.parentNode.classList.remove('disabled');

	document.getElementById("previous").disabled = this.currentPage == 1 ? true : false;
		this.currentPage == 1 ? document.getElementById("previous")
		.parentNode.classList.add('disabled') : document.getElementById("previous")
		.parentNode.classList.remove('disabled');

    document.getElementById("first").disabled = this.currentPage == 1 ? true : false;

    document.getElementById("last").disabled = this.currentPage == this.numPages ? true : false;
};



// var test = new Pager(document.querySelector(".directory-list"), 15)