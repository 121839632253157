import {Spinner} from 'spin.js'
import Pager from './pagination.mjs'

// Rewrite, take 2
(function() { 

var opts = {
    lines: 13, // The number of lines to draw
    length: 38, // The length of each line
    width: 15, // The line thickness
    radius: 35, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#999999', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    top: '10%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    zIndex: 2000000000, // The z-index (defaults to 2e9)
    className: 'spinner', // The CSS class to assign to the spinner
    position: 'absolute', // Element positioning
    };
 

function testValidEl(test) {
    if (!test) {
        throw new Error("Page elements missing");
    }
}    
function testArrayNotEmpty(test) {
    if (!(test.length > 0 && test)) {
        throw new Error("Page elements missing");
    }
} 

function directoryListing() {
    //declare and set variables
    
    this.wrapper = document.querySelector(".faculty__wrapper");
    testValidEl(this.wrapper)
    this.nav = this.wrapper.querySelector(".alphaSelect");
    testValidEl(this.nav)
    this.navs = this.nav.querySelectorAll(".siteMapNav");
    testArrayNotEmpty(this.navs)
    this.navs = this.makeArray(this.navs)
    this.listing = this.wrapper.querySelector(".directory-list");    
    testValidEl(this.listing)
    
    this.rows = this.listing.querySelectorAll(".deptRow");
    this.rows = this.makeArray(this.rows)
    this.currentFilter = null;
    this.queue = {
        operations: [],
        execute() {
            // this.operations.forEach((item) => console.log(item))
            while(this.operations.length) {
                let op = this.operations.shift();
                this[op[0]](op[1], op[2]);
            }
        },
        register(func, element, params) {
            this.operations.push([func, element, params])
        },
        attributeChange(el, args) {
            el.setAttribute(args[0], args[1])
        },
        styleChange(el, args) {
            el.style[args[0]] = args[1];
        },
        addClass(el, cl) {
            el.classList.add(cl);
        },
        removeClass(el, args) {
            el.classList.remove(args)
        }
    },
    //bind functions
    this.makeArray = this.makeArray.bind(this);
    this.hasResults = this.hasResults.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.showRow = this.showRow.bind(this);
    this.hideRow = this.hideRow.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
    this.render = this.render.bind(this);
    this.execute = this.execute.bind(this);
    this.keyHandler = this.keyHandler.bind(this);
    //initializer functions
    this.initAlphaNavs();
    this.pager = new Pager(this.listing, 15)
    //set event listeners
    // this.nav.addEventListener("keydown", function(event) {
    //     filterState.handleRadioKey(event, filterState.nav);
    //   })
    this.nav.addEventListener("keydown", this.keyHandler);
    this.nav.addEventListener("click", this.handleClick);
}
directoryListing.prototype.render = function() {
    let spinner = new Spinner(opts).spin(this.wrapper);
    this.listing.remove();
    this.queue.execute();
    this.wrapper.appendChild(this.listing);
    spinner.stop();
}
directoryListing.prototype.execute = function() {
    this.queue.execute();
}
directoryListing.prototype.showRow = function(row) {
    if (row.tagName == "TR") {
        // this.listing[i].style.display = "table-row"; 
        this.queue.register("styleChange", row, ["display", "table-row"])
    } else {
        // this.listing[i].style.display = "grid"; 
        this.queue.register("styleChange", row, ["display", "grid"])
    }
}
directoryListing.prototype.hideRow = function(row) {
    this.queue.register("styleChange", row, ["display", "none"]);
}
directoryListing.prototype.setCurrent = function(el) {
    let old = null
    if (this.current) {
        this.queue.register("attributeChange", this.current, ["aria-checked", "false"])
        this.queue.register("removeClass", this.current, "selected")
        old = this.current
    }
    this.current = el;
    this.queue.register("attributeChange", this.current, ["aria-checked", "true"])
    this.queue.register("addClass", this.current, "selected")
    if (old) {
        this.queue.register("attributeChange", old, ["tabindex", "-1"])  
    }
    this.queue.register("attributeChange", this.current, ["tabindex", "0"])  
}
directoryListing.prototype.handleClick = function (event) {
    // console.log("handling click")
    // console.log(event)
    // console.log(event.target)
    if (!event.target.classList.contains("siteMapNav")) {
        return false;
    }

    if (event.target.id == "all" || event.target.name == "all") {
        for (let i = 0; i < this.rows.length; i++) {
            // console.log(this.rows[i]);
            this.showRow(this.rows[i]);
        }
    } else {
        var filter = event.target.name != "" ? event.target.name : event.target.id;
        // console.log(filter)
        for (let i = 0; i < this.rows.length; i++) {    

            // console.log(this.rows[i]);
            // console.log("What Letter:", filter)
            // console.log("Classes:", array[i].classList)
            if (this.rows[i].classList.contains(filter)) {
                // console.log("SUCCESS")
                this.showRow(this.rows[i]);
            } else {
                // console.log("HIDE!")
                this.hideRow(this.rows[i])
            }
        }
    }

    this.setCurrent(event.target);
    this.render();
  
    if (!event.target.hasFocus) {
      event.target.focus();
    }

}
directoryListing.prototype.keyHandler = function (event) {
    var activeIndex = this.navs.indexOf(event.target);
    var targetIndex = 0;
    switch (event.key) {
      case "ArrowRight":
        targetIndex = this.calcForward(activeIndex, this.navs);
        this.goToTarget(this.navs, targetIndex);
        break;
      case "ArrowLeft":
        targetIndex = this.calcBack(activeIndex, this.navs);
        this.goToTarget(this.navs, targetIndex);
        break;
      case "Home":
        targetIndex = 0;
        this.goToTarget(this.navs, targetIndex);
        event.preventDefault();
        break;  
      case "End":
        targetIndex = this.calcEnd(this.navs.length, this.navs);
        this.goToTarget(this.navs, targetIndex);
        event.preventDefault();
        break;
      case "Space":
        targetIndex = activeIndex;
        this.goToTarget(this.navs, targetIndex);
        event.preventDefault();
        break;    
      case "ArrowDown":
        targetIndex = this.calcForward(activeIndex, this.navs);
        this.goToTarget(this.navs, targetIndex);
        break;
      case "ArrowUp":
        targetIndex = this.calcBack(activeIndex, this.navs);
        this.goToTarget(this.navs, targetIndex);
        break;    
      // case "Enter":
      //   targetIndex = activeIndex;
      //   this.goToTarget(this.navs, targetIndex);
      //   break;  
      default:  
        targetIndex = -1;  
    }
}
directoryListing.prototype.goToTarget = function(array, target) {
    if (target >= 0) { 
        // array[target].focus()
        array[target].click()    
      }
}
directoryListing.prototype.calcEnd = function(finalIndex, navsArray) {
    let temp = finalIndex - 1
    if (this.navs[temp].getAttribute("aria-disabled")) {
        return this.calcEnd(temp, navsArray)
    }

    return temp;
}
directoryListing.prototype.calcForward = function(activeIndex, navsArray) {
    let temp = (activeIndex + 1) % navsArray.length;

    if (this.navs[temp].getAttribute("aria-disabled")) {
        return this.calcForward(temp, navsArray);
    }
    return temp;
}
directoryListing.prototype.calcBack = function(activeIndex, navsArray) {
    let temp = (((activeIndex - 1) % navsArray.length) + navsArray.length) % navsArray.length;

    if (this.navs[temp].getAttribute("aria-disabled")) {
        return this.calcBack(temp, navsArray)
    }

    return temp;
}
directoryListing.prototype.makeArray = function (nodeList) {
    let temp = [];
    for (let i = 0; i < nodeList.length; i++) {
        temp.push(nodeList[i])
    };
    return temp;
}
directoryListing.prototype.initAlphaNavs = function () {
    for (let x = 0; x < this.navs.length; x++) {
        let letter = this.navs[x].name && this.navs[x].name != "" ? this.navs[x].name : this.navs[x].id;

        if (letter == "all") {
          this.current = this.navs[x];  
          continue;
        }
        
        if (!this.hasResults(letter)) {
            this.disableFilter(this.navs[x])
        }
    }
    this.queue.execute();
}

directoryListing.prototype.hasResults = function(letter) {
    let result = this.rows.filter(function (item) {
        return item.classList.contains(letter);
      });
    return result.length > 0; 
}

directoryListing.prototype.disableFilter = function(nav) {
    this.queue.register("addClass", nav, ["disabled"])
    this.queue.register("attributeChange", nav, ['aria-disabled', true])
}



if (document.readyState === "loading") {
    // Loading hasn't finished yet
    window.addEventListener("load", init);
  } else {
    // `DOMContentLoaded` has already fired
    init();
  }

function init() {
var directory = new directoryListing();
}

})();
